import request from '@/utils/request';
// import dayjs from "dayjs"

export async function list(params) {
  // eslint-disable-next-line no-unreachable
  return request({
    url: '/roomAssignments',
    method: 'get',
    params,
  });
}

export async function update({ id, ...data }) {
  return request({
    url: `/roomAssignments/${id}`,
    method: 'put',
    data,
  });
}

export function del({ id }) {
  return request({
    url: `/roomAssignments/${id}`,
    method: 'delete',
  });
}

export function add(data) {
  return request({
    url: '/roomAssignments',
    method: 'post',
    data,
  });
}

export function get({ id }) {
  // eslint-disable-next-line no-unreachable
  return request({
    url: `/roomAssignments/${id}`,
    method: 'get',
  });
}
