import request from '@/utils/request';

export function login(data, headers) {
  return request({
    url: '/users/login',
    method: 'post',
    data,
    headers,
    withCredentials: true,
  });
}

export function refreshToken() {
  return request({
    url: '/users/refresh-token',
    method: 'post',
    withCredentials: true,
  });
}

export function getInfo() {
  return request({
    url: '/users/info',
    method: 'get',
    // headers: {'Authorization': `Bearer ${token}`}
  });
}

export function logout(data) {
  return request({
    url: '/users/logout',
    method: 'post',
    data,
    withCredentials: true,
  });
}

export function users(params) {
  return request({
    url: '/users',
    method: 'get',
    params,
  });
}

export function update({ uid, ...data }) {
  return request({
    url: `/users/${uid}`,
    method: 'put',
    data,
  });
}

export function config(data) {
  return request({
    url: '/users/me/config',
    method: 'put',
    data,
  });
}

export function getConfig() {
  return request({
    url: '/users/me/config',
    method: 'get',
  });
}

export function deleteUser({ uid }) {
  return request({
    url: `/users/${uid}`,
    method: 'delete',
  });
}

export function add(data) {
  return request({
    url: '/users',
    method: 'post',
    data,
  });
}

export function get({ uid }) {
  return request({
    url: `/users/one/${uid}`,
    method: 'get',
  });
}

export function forgotPassword(data) {
  return request({
    url: '/users/forgotPassword',
    method: 'post',
    data,
  });
}

export function resetPassword(data) {
  return request({
    url: '/users/resetPassword',
    method: 'post',
    data,
  });
}

export function changePassword(data) {
  return request({
    url: '/users/password/reset',
    method: 'put',
    data,
  });
}

export function resendEmail(data) {
  return request({
    url: '/users/resendEmail',
    method: 'post',
    data,
  });
}

export function verifyPasswordToken(data) {
  return request({
    url: '/users/verify-password-token',
    method: 'post',
    data,
  });
}

export function addDevice(data) {
  return request({
    url: '/users/devices',
    method: 'post',
    data,
  });
}
