import { Service } from '@/utils/request';

const request = Service({
  baseURL: `${process.env.VUE_APP_KAITORI_API_HOST}/api/saved-data`,
  timeout: 480000,
});

function saveData(type, data) {
  return request({
    url: `/${type}`,
    method: 'post',
    data,
  });
}

function getData(type, params) {
  let { fromDate, toDate } = params.availablePeriod;
  let { dates } = params;
  delete params.dates;
  delete params.availablePeriod;

  if (dates?.length) {
    dates = dates.sort((a, b) => (a > b ? 1 : -1));
    fromDate = dates.shift();
    toDate = dates.pop();
  }

  const query = [];
  if (fromDate != null) query.push(`availablePeriod[fromDate]=${fromDate}`);
  if (toDate != null) query.push(`availablePeriod[toDate]=${toDate}`);

  return request({
    url: `/${type}?${query.join('&')}`,
    params,
  });
}

export function saveRatePerRoomData(body) {
  return saveData('per-room', body);
}

export function saveRatePerPersonData(body) {
  return saveData('per-person', body);
}

export function getRatePerRoomData(body) {
  return getData('per-room', body);
}

export function getRatePerPersonData(body) {
  return getData('per-person', body);
}
