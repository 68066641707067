import request from '@/utils/request';

export async function list({ partnerId, params }) {
  // return {
  //     list: [
  //         {
  //             id: '1',
  //             name: "Machi Koji",
  //             email: "machi.k@cleanb.com",
  //             telephone: '7654321',
  //             isActive: true,
  //             fax: '7654321'
  //         }
  //     ]
  // }
  // eslint-disable-next-line no-unreachable
  return request({
    url: `/partners/${partnerId}/persons-in-charge`,
    method: 'get',
    params,
  });
}

export async function update({ id, ...data }) {
  return request({
    url: `/partners/${data.partnerId}/persons-in-charge/${id}`,
    method: 'put',
    data,
  });
}

export function del({ id, partnerId }) {
  return request({
    url: `/partners/${partnerId}/persons-in-charge/${id}`,
    method: 'delete',
  });
}

export function add(data) {
  return request({
    url: `/partners/${data.partnerId}/persons-in-charge`,
    method: 'post',
    data,
  });
}

export function get({ id }) {
  // return {}

  // eslint-disable-next-line no-unreachable
  return request({
    url: `/persons-in-charge/${id}`,
    method: 'get',
  });
}
