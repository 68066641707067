import simulationApi from './api';
import { CancelToken } from '@/utils/request';

let destinationsSource = CancelToken.source();
let propertiesSource = CancelToken.source();
let propertySource = CancelToken.source();
let roomPricesSource = CancelToken.source();
let reservationSource = CancelToken.source();

export async function destinations(params) {
  await destinationsSource.cancel('SIMULATIONS_DESTINATIONS');
  destinationsSource = CancelToken.source();
  const resp = await simulationApi({
    url: '/extractions/destinations',
    method: 'get',
    params,
    cancelToken: destinationsSource.token,
  });
  return resp;
}

export async function properties(params) {
  await propertiesSource.cancel('SIMULATIONS_PROPERTIES');
  propertiesSource = CancelToken.source();
  const resp = await simulationApi({
    url: '/extractions/properties',
    method: 'get',
    params,
    cancelToken: propertiesSource.token,
  });
  return resp;
}

export function extractionsExport(params) {
  return simulationApi({
    url: '/extractions/export',
    method: 'post',
    params,
    responseType: 'blob',
  });
}

export async function getProperty(id) {
  await propertySource.cancel('SIMULATIONS_EXTRACTIONS_PROPERTY');
  propertySource = CancelToken.source();
  const resp = await simulationApi({
    url: `/extractions/properties/${id}`,
    method: 'get',
  });
  return resp;
}

export async function getRoomPrices(id, params) {
  await roomPricesSource.cancel('SIMULATIONS_EXTRACTIONS_ROOM_PRICES');
  roomPricesSource = CancelToken.source();
  const resp = await simulationApi({
    url: `/extractions/properties/${id}/rooms`,
    params,
    method: 'get',
  });

  return resp;
}

export async function getReservation(id, params) {
  await reservationSource.cancel('SIMULATIONS_EXTRACTIONS_RESERVATION');
  reservationSource = CancelToken.source();
  const resp = await simulationApi({
    url: `/extractions/properties/${id}/reservations`,
    params,
    method: 'get',
  });

  return resp;
}
