import request from '@/utils/request';

export function list(params) {
  return request({
    url: 'stocks',
    params,
  });
}

export function add(data) {
  return request({
    url: 'stocks',
    method: 'post',
    data,
  });
}

export function get(id) {
  return request({
    url: `stocks/${id}`,
  });
}

export function setItemImage({ id, stockId, file }) {
  const formData = new FormData();
  if (file) {
    formData.append('image', file.originFileObj || file);
  }

  return request({
    url: `stocks/${stockId}/items/${id}/image`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}

export function update({ id, ...data }) {
  return request({
    url: `stocks/${id}`,
    method: 'put',
    data,
  });
}

export function updateQuantities({ items }) {
  return request({
    url: 'stocks/quantities',
    method: 'post',
    data: { items },
  });
}

export function setRooms({ id, roomIds }) {
  return request({
    url: `stocks/${id}/rooms`,
    method: 'post',
    data: { roomIds },
  });
}

export function del(id) {
  return request({
    url: `stocks/${id}`,
    method: 'delete',
  });
}

export function deleteItems({ stockId, ids }) {
  return request({
    url: `stocks/${stockId}/items`,
    method: 'delete',
    data: { ids },
  });
}
