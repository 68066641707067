import rmsApi from './api';

export function listRms(params) {
  return rmsApi({
    url: '/settings',
    params,
  });
}

export function getRms({ id }) {
  return rmsApi({
    url: `/settings/${id}`,
  });
}

export function addRms(data) {
  return rmsApi({
    url: '/settings',
    method: 'post',
    data,
  });
}

export function editRms({ id, ...data }) {
  return rmsApi({
    url: `/settings/${id}`,
    method: 'put',
    data,
  });
}

export function deleteRms({ id }) {
  return rmsApi({
    url: `/settings/${id}`,
    method: 'delete',
  });
}

export function saveCompetitor({ settingId, ...data }) {
  return rmsApi({
    url: `/settings/${settingId}/competitors`,
    method: 'post',
    data,
  });
}
