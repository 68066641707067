import { Service } from '@/utils/request';

const request = Service({
  baseURL: `${process.env.VUE_APP_KAITORI_API_HOST}/api/properties`,
  timeout: 480000,
});

export function list(vendor) {
  return request({
    url: '',
    params: { vendor },
  });
}

export function roomList(hotelNo, type, vendor) {
  return request({
    url: `/${hotelNo}/rooms`,
    params: { type, vendor },
  });
}
