import { Service } from '@/utils/request';

const request = Service({
  baseURL: `${process.env.VUE_APP_KAITORI_API_HOST}/api/piop-scm`,
  timeout: 480000,
});

export function ratePerRoom(params) {
  let { fromDate, toDate } = params.availablePeriod;
  let { dates } = params;
  delete params.dates;
  delete params.vendor;
  delete params.availablePeriod;

  if (dates?.length) {
    dates = dates.sort((a, b) => (a > b ? 1 : -1));
    fromDate = dates.shift();
    toDate = dates.pop();
  }

  const query = [];
  if (fromDate != null) query.push(`availablePeriod[fromDate]=${fromDate}`);
  if (toDate != null) query.push(`availablePeriod[toDate]=${toDate}`);

  return request({
    url: `per-room?${query.join('&')}`,
    params,
  });
}

export function ratePerPerson(params) {
  let { fromDate, toDate } = params.availablePeriod;
  let { dates } = params;
  delete params.dates;
  delete params.vendor;
  delete params.availablePeriod;

  if (dates?.length) {
    dates = dates.sort((a, b) => (a > b ? 1 : -1));
    fromDate = dates.shift();
    toDate = dates.pop();
  }

  const query = [];
  if (fromDate != null) query.push(`availablePeriod[fromDate]=${fromDate}`);
  if (toDate != null) query.push(`availablePeriod[toDate]=${toDate}`);

  return request({
    url: `per-person?${query.join('&')}`,
    params,
  });
}
