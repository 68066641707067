import request from '@/utils/request';

export async function list(params) {
  // return {
  //     list: [
  //         {
  //             id: '1',
  //             partner: "Partner a",
  //             status: 'waiting approval',
  //             requestDate: '2021-07-02',
  //             feeType: 'fixed cost',
  //             itemName: 'lorem ipsum',
  //             remarks: 'lorem ipsum',
  //             costPerMonth: 2,
  //             currency: 'CNY',
  //             covers: '',
  //             occurs: '',
  //             paymentPeriod: '2021-07-02'
  //         },
  //         {
  //             id: '2',
  //             partner: "Partner b",
  //             status: 'rejected',
  //             requestDate: '2021-07-02',
  //             feeType: 'variable cost',
  //             itemName: 'lorem ipsum',
  //             remarks: 'lorem ipsum',
  //             costPerMonth: 2,
  //             currency: 'CNY',
  //             covers: '',
  //             occurs: '',
  //             paymentPeriod: '2021-07-02'
  //         },
  //         {
  //             id: '3',
  //             partner: "Partner c",
  //             status: 'approved',
  //             requestDate: '2021-07-02',
  //             feeType: 'variable cost',
  //             itemName: 'lorem ipsum',
  //             remarks: 'lorem ipsum',
  //             costPerMonth: 2,
  //             currency: 'CNY',
  //             covers: '',
  //             occurs: '',
  //             paymentPeriod: '2021-07-02'
  //         }
  //     ]
  // }
  // eslint-disable-next-line no-unreachable
  return request({
    url: '/payments',
    method: 'get',
    params,
  });
}

export async function update({ id, ...data }) {
  return request({
    url: `/payments/${id}`,
    method: 'put',
    data,
  });
}

export function del({ id }) {
  return request({
    url: `/payments/${id}`,
    method: 'delete',
  });
}

export function add(data) {
  return request({
    url: '/payments',
    method: 'post',
    data,
  });
}

export function get({ id }) {
  // return {
  //     id: '1',
  //     partner: "Partner a",
  //     status: 'waiting approval',
  //     requestDate: '2021-07-02',
  //     feeType: 'fixed cost',
  //     itemName: 'lorem ipsum',
  //     remarks: 'lorem ipsum',
  //     costPerMonth: 2,
  //     currency: 'CNY',
  //     covers: '',
  //     occurs: '',
  //     paymentPeriod: '2021-07-02'
  // }

  // eslint-disable-next-line no-unreachable
  return request({
    url: `/payments/${id}`,
    method: 'get',
  });
}
