import rmsApi from './api';

export function lists(params) {
  return rmsApi({
    url: '/toplines/checks',
    params,
  });
}

export function checks(data) {
  return rmsApi({
    url: '/toplines/checks',
    method: 'POST',
    data,
  });
}
