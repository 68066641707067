import request, { CancelToken } from '@/utils/request';

let namesSource = CancelToken.source();

export async function list(params) {
  return request({
    url: '/rooms',
    method: 'get',
    params,
  });
}

export async function names({ cancellable = true, ...params }) {
  const opts = {
    url: '/rooms/names',
    method: 'get',
    params,
  };

  if (cancellable === true) {
    namesSource.cancel('ROOM_NAMES_CANCEL');
    namesSource = CancelToken.source();

    opts.cancelToken = namesSource.token;
  }

  const response = await request(opts);

  return response;
}

export async function inspectionList(id) {
  return request({
    url: `/rooms/${id}/inspection-lists`,
    method: 'get',
  });
}

export function stocks(id) {
  return request({
    url: `rooms/${id}/stocks`,
    method: 'get',
  });
}

export async function assignmentList(params) {
  return request({
    url: '/rooms/assignments',
    params,
  });
}

export async function update({ id, ...data }) {
  return request({
    url: `/rooms/${id}`,
    method: 'put',
    data,
  });
}

export function uploadDiagram({ id, file }) {
  const formData = new FormData();
  formData.append('diagram', file.originFileObj || file);

  return request({
    url: `/rooms/${id}/diagram`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}

export function del({ id }) {
  return request({
    url: `/rooms/${id}`,
    method: 'delete',
  });
}

export function delInspectionRoom({ listId, roomId }) {
  return request({
    url: `/rooms/${roomId}/inspection-lists/${listId}`,
    method: 'delete',
  });
}

export function add(data) {
  return request({
    url: '/rooms',
    method: 'post',
    data,
  });
}

export function addInspectionList({ roomId, inspectionId }) {
  return request({
    url: `/rooms/${roomId}/inspection-lists/${inspectionId}`,
    method: 'post',
  });
}

export function get({ id }) {
  return request({
    url: `/rooms/one/${id}`,
    method: 'get',
  });
}

export function listAnshinAccommodations(params) {
  return request({
    url: 'rooms/anshin',
    params,
  });
}

export function listNeppanRoomTypes({ propertyId }) {
  return request({
    url: `rooms/neppan/${propertyId}`,
  });
}
