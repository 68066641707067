import request from '@/utils/request';

export async function list(params) {
  return request({
    url: '/inspection-lists',
    method: 'get',
    params,
  });
}

export async function update({ id, ...data }) {
  return request({
    url: `/inspection-lists/${id}`,
    method: 'put',
    data,
  });
}

export function setRooms({ id, roomIds }) {
  return request({
    url: `inspection-lists/${id}/rooms`,
    method: 'post',
    data: { roomIds },
  });
}

export async function addItemImage({ listId, id, file }) {
  const formData = new FormData();
  if (file) {
    formData.append('images', file.originFileObj || file);
  }

  return request({
    url: `/inspection-lists/${listId}/items/${id}/images`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}

export function del({ id }) {
  return request({
    url: `/inspection-lists/${id}`,
    method: 'delete',
  });
}

export function add(data) {
  return request({
    url: '/inspection-lists',
    method: 'post',
    data,
  });
}

export function get(id) {
  return request({
    url: `/inspection-lists/${id}`,
    method: 'get',
  });
}

export function deleteItems({ listId, ids }) {
  return request({
    url: `inspection-lists/${listId}/items`,
    method: 'delete',
    data: { ids },
  });
}
