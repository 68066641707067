import simulationApi from './api';

export async function get(params) {
  return simulationApi({
    url: '/spdb',
    method: 'get',
    params,
  });
}

export async function result(params) {
  return simulationApi({
    url: '/spdb/result',
    method: 'get',
    params,
  });
}
