import request from '@/utils/request';

export async function list(params) {
  return request({
    url: 'system-logs',
    method: 'get',
    params,
  });
}

export async function update({ id, ...data }) {
  return request({
    url: `/system-logs/${id}`,
    method: 'put',
    data,
  });
}

export function add(data) {
  return request({
    url: '/system-logs',
    method: 'post',
    data,
  });
}

export function get(id) {
  return request({
    url: `/system-logs/${id}`,
    method: 'get',
  });
}
