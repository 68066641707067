import request from '@/utils/request';

export function listTemplate(params) {
  return request({
    url: '/guest-email/templates',
    method: 'get',
    params,
  });
}

export function showTemplate(id) {
  return request({
    url: `/guest-email/templates/${id}`,
    method: 'get',
  });
}

export function createTemplate(data) {
  return request({
    url: '/guest-email/templates',
    method: 'post',
    data,
  });
}

export function updateTemplate({ id, ...data }) {
  return request({
    url: `/guest-email/templates/${id}`,
    method: 'put',
    data,
  });
}

export function deleteTemplate(id) {
  return request({
    url: `/guest-email/templates/${id}`,
    method: 'delete',
  });
}

export function getSender({ propertyId, ...params }) {
  return request({
    url: `/properties/${propertyId}/guest-email-sender`,
    method: 'get',
    params,
  });
}

export function updateSender(data) {
  const { propertyId } = data;
  return request({
    url: `/properties/${propertyId}/guest-email-sender`,
    method: 'post',
    data,
  });
}

export function listEventLog(params) {
  return request({
    url: '/guest-email/event-logs',
    method: 'get',
    params,
  });
}

export function showEventLog(id) {
  return request({
    url: `/guest-email/event-logs/${id}`,
    method: 'get',
  });
}

export function sendEmail({ id, ...data }) {
  return request({
    url: `/guest-email/event-logs/${id}/send`,
    method: 'post',
    data,
  });
}

export function latestEventLog(params) {
  return request({
    url: '/guest-email/event-logs/latest',
    method: 'get',
    params,
  });
}
