import rmsApi from './api';

export function list(params) {
  return rmsApi({
    url: '/rates/plans',
    params,
  });
}

export function get({ id }) {
  return rmsApi({
    url: `/rates/plans/${id}`,
  });
}

export function add(data) {
  return rmsApi({
    url: '/rates/plans',
    method: 'post',
    data,
  });
}

export function update({ id, ...data }) {
  return rmsApi({
    url: `/rates/plans/${id}`,
    method: 'put',
    data,
  });
}

export function deletePlan({ id }) {
  return rmsApi({
    url: `/rates/plans/${id}`,
    method: 'delete',
  });
}
