import simulationApi from './api';

export async function small(params) {
  return simulationApi({
    url: '/areas/small',
    method: 'get',
    params,
  });
}

export async function detail(params) {
  return simulationApi({
    url: '/areas/detail',
    method: 'get',
    params,
  });
}
