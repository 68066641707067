import rmsApi from './api';

export function list(params) {
  return rmsApi({
    url: '/rates',
    params,
  });
}

export function exportRates(params) {
  return rmsApi({
    url: '/rates/export',
    method: 'post',
    params,
    responseType: 'blob',
  });
}

export function saveByProperty({ propertyId, rates }) {
  return rmsApi({
    url: `/rates/${propertyId}`,
    method: 'post',
    data: {
      rates,
    },
  });
}

export function neppan({ propertyId, ...params }) {
  return rmsApi({
    url: `/rates/${propertyId}/neppan`,
    params,
  });
}

export function neppanPlans({ propertyId, ...params }) {
  return rmsApi({
    url: `/rates/${propertyId}/neppan/plans`,
    params,
  });
}

export function submitToNeppan({ propertyId, rates }) {
  return rmsApi({
    url: `/rates/${propertyId}/neppan`,
    method: 'post',
    data: {
      rates,
    },
  });
}
