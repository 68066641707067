import request from '@/utils/request';

export async function list(params) {
  return request({
    url: '/partners',
    method: 'get',
    params,
  });
}

export async function names(params) {
  return request({
    url: '/partners/names',
    method: 'get',
    params,
  });
}

export async function update({ id, ...data }) {
  return request({
    url: `/partners/${id}`,
    method: 'put',
    data,
  });
}

export function deleteUser({ id }) {
  return request({
    url: `/partners/${id}`,
    method: 'delete',
  });
}

export function add(data) {
  return request({
    url: '/partners',
    method: 'post',
    data,
  });
}

export function get({ id }) {
  return request({
    url: `/partners/${id}`,
    method: 'get',
  });
}
