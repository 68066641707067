import simulationApi from './api';

export function list(params) {
  return simulationApi({
    url: '',
    method: 'get',
    params,
  });
}

export function search(payload) {
  const { cancelToken, ...params } = payload;

  return simulationApi({
    url: '/search',
    method: 'get',
    params,
    cancelToken,
  });
}

export function exportSimulation(data) {
  return simulationApi({
    url: '/export',
    method: 'post',
    data,
    responseType: 'arraybuffer',
  });
}

export function getRooms(payload) {
  const { cancelToken, ...params } = payload;

  return simulationApi({
    url: '/search/rooms',
    method: 'get',
    params,
    cancelToken,
  });
}

export function get(id) {
  return simulationApi({
    url: `/${id}`,
    method: 'get',
  });
}

export function occBefore(params) {
  return simulationApi({
    url: '/occ-before',
    method: 'get',
    params,
  });
}

export function latestVersion(requestId) {
  return simulationApi({
    url: `/requests/${requestId}/versions/latest`,
    method: 'get',
  });
}

export function getVersion({ requestId, version }) {
  return simulationApi({
    url: `/requests/${requestId}/versions/${version}`,
    method: 'get',
  });
}

export async function update({ id, ...data }) {
  return simulationApi({
    url: `/${id}`,
    method: 'put',
    data,
  });
}

export function del(id) {
  return simulationApi({
    url: `/${id}`,
    method: 'delete',
  });
}

export function add(data) {
  return simulationApi({
    url: '',
    method: 'post',
    data,
  });
}

// general add-ons
export function getGeneralAddonLists(params) {
  return simulationApi({
    url: '/general-addons',
    method: 'get',
    params,
  });
}

export function updateGeneralAddon({ id, ...data }) {
  return simulationApi({
    url: `/general-addons/${id}`,
    method: 'put',
    data,
  });
}

export function addGeneralAddon(data) {
  return simulationApi({
    url: '/general-addons',
    method: 'post',
    data,
  });
}

export function deleteGeneralAddon({ id }) {
  return simulationApi({
    url: `/general-addons/${id}`,
    method: 'delete',
  });
}

// calculation adjustment
export function getCalculationAdjustment() {
  return simulationApi({
    url: '/calculation-adjustments',
    method: 'get',
  });
}

export function saveCalculationAdjustment(data) {
  return simulationApi({
    url: '/calculation-adjustments',
    method: 'post',
    data,
  });
}

// room add-ons
export function listRoomAddons(params) {
  return simulationApi({
    url: '/room-addons',
    method: 'get',
    params,
  });
}

export function addRoomAddon(data) {
  return simulationApi({
    url: '/room-addons',
    method: 'post',
    data,
  });
}

export function updateRoomAddon({ id, ...data }) {
  return simulationApi({
    url: `/room-addons/${id}`,
    method: 'put',
    data,
  });
}

export function deleteRoomAddon({ id }) {
  return simulationApi({
    url: `/room-addons/${id}`,
    method: 'delete',
  });
}

// room types
export async function getRoomTypes(id) {
  return simulationApi({
    url: `/${id}/room-types`,
    method: 'get',
  });
}

export function deleteRoomTypes({ simulationId, ids }) {
  return simulationApi({
    url: `/${simulationId}/room-types`,
    method: 'delete',
    data: { ids },
  });
}

// prefecture OCC
export function getPrefectureLists(params) {
  return simulationApi({
    url: '/prefecture-occ',
    method: 'get',
    params,
  });
}

export function prefectureOccAverages(params) {
  return simulationApi({
    url: '/prefecture-occ/averages',
    method: 'get',
    params,
  });
}

export function updatePrefecture({ id, ...data }) {
  return simulationApi({
    url: `/prefecture-occ/${id}`,
    method: 'put',
    data,
  });
}

export function addPrefecture(data) {
  return simulationApi({
    url: '/prefecture-occ',
    method: 'post',
    data,
  });
}

export function deletePrefecture({ id }) {
  return simulationApi({
    url: `/prefecture-occ/${id}`,
    method: 'delete',
  });
}

// external room size
export function getRoomSize(params) {
  return simulationApi({
    url: '/room-sizes',
    method: 'get',
    params,
  });
}

export function addRoomSize(data) {
  return simulationApi({
    url: '/room-sizes',
    method: 'post',
    data,
  });
}

export function updateRoomSize({ id, ...data }) {
  return simulationApi({
    url: `/room-sizes/${id}`,
    method: 'put',
    data,
  });
}

export function deleteRoomSize({ id }) {
  return simulationApi({
    url: `/room-sizes/${id}`,
    method: 'delete',
  });
}

// external reservation meal
export function getReservationMeal(params) {
  return simulationApi({
    url: '/reservation-meals',
    method: 'get',
    params,
  });
}

export function saveReservationMeal({ reservationNumber, ...data }) {
  return simulationApi({
    url: `/reservation-meals/${reservationNumber}`,
    method: 'put',
    data,
  });
}

export function deleteReservationMeal(reservationNumber) {
  return simulationApi({
    url: `/reservation-meals/${reservationNumber}`,
    method: 'delete',
  });
}
