import { Service } from '@/utils/request';

const request = Service({
  baseURL: `${process.env.VUE_APP_KAITORI_API_HOST}/api/price-auto-reflection`,
  timeout: 480000,
});

export function externalVendors() {
  return request({
    url: 'external-vendors',
  });
}

export function list(propertyIds) {
  return request({
    url: '',
    params: {
      propertyIds,
    },
  });
}

export function saveData(data) {
  return request({
    url: '',
    method: 'post',
    data,
  });
}
