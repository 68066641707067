import request from '@/utils/request';

export function list(params) {
  return request({
    url: 'out-of-order',
    params,
  });
}

export function add(data) {
  return request({
    url: 'out-of-order',
    method: 'post',
    data,
  });
}

export function get(id) {
  return request({
    url: `out-of-order/${id}`,
  });
}

export function addImages({ id, file }) {
  const formData = new FormData();
  file.forEach((f) => {
    if (f.id === undefined) {
      formData.append('images', f.originFileObj || f);
    }
  });

  return request({
    url: `out-of-order/${id}/images`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}

export function update({ id, ...data }) {
  return request({
    url: `out-of-order/${id}`,
    method: 'put',
    data,
  });
}

export function del(id) {
  return request({
    url: `out-of-order/${id}`,
    method: 'delete',
  });
}

export function deleteImage({ id, imageId }) {
  return request({
    url: `out-of-order/${id}/images/${imageId}`,
    method: 'delete',
  });
}
