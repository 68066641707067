import rmsApi from './api';

export function list(params) {
  return rmsApi({
    url: '/locked-dates',
    params,
  });
}

export function get({ id }) {
  return rmsApi({
    url: `/locked-dates/${id}`,
  });
}

export function create(data) {
  return rmsApi({
    url: '/locked-dates',
    method: 'post',
    data,
  });
}

export function update({ id, ...data }) {
  return rmsApi({
    url: `/locked-dates/${id}`,
    method: 'put',
    data,
  });
}

export function deleteRecord({ id }) {
  return rmsApi({
    url: `/locked-dates/${id}`,
    method: 'delete',
  });
}
