import request from '@/utils/request';

export async function list(params) {
  return {
    list: [
      {
        id: '1',
        name: 'lorem ipsum',
        description: 'lorem ipsum',
        property: {
          id: '1',
          name: 'lorem ipsum',
        },
        room: {
          id: '1',
          name: 'room 101',
        },
        isActive: true,
        reportedTime: '2021-02-02 10:54',
      },
      {
        id: '2',
        name: 'lorem ipsum',
        description: 'lorem ipsum',
        property: {
          id: '2',
          name: 'lorem ipsum',
        },
        room: {
          id: '2',
          name: 'room 102',
        },
        isActive: false,
        reportedTime: '2021-02-02 10:54',
      },
    ],
  };
  // eslint-disable-next-line no-unreachable
  return request({
    url: `out-of-order/${params.id}`,
    method: 'get',
    params,
  });
}

export async function update({ id, ...data }) {
  return request({
    url: `/out-of-order/${id}`,
    method: 'put',
    data,
  });
}

export function del({ id }) {
  return request({
    url: `/out-of-order/${id}`,
    method: 'delete',
  });
}

export function add(data) {
  return request({
    url: '/out-of-order',
    method: 'post',
    data,
  });
}

export function get({ id }) {
  return {
    id: '1',
    name: 'lorem ipsum',
    description: 'lorem ipsum',
    property: {
      id: '1',
      name: 'lorem ipsum',
    },
    room: {
      id: '1',
      name: 'room 101',
    },
    isActive: true,
    reportedTime: '2021-02-02 10:54',
    pictures: [
      {
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      },
      {
        uid: '-2',
        name: 'image.png',
        status: 'done',
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      },
    ],
  };

  // eslint-disable-next-line no-unreachable
  return request({
    url: `/out-of-order/${id}`,
    method: 'get',
  });
}
