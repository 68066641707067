import omsApi from './api';

export async function list(params) {
  const dateKey = Object.keys(params).filter((key) => /Date$/i.test(key))[0];
  const query = [];
  if (dateKey) {
    const { fromDate, toDate } = params[dateKey];
    delete params[dateKey];
    if (fromDate != null) query.push(`${dateKey}[fromDate]=${fromDate}`);
    if (toDate != null) query.push(`${dateKey}[toDate]=${toDate}`);
  }
  return omsApi({
    url: `/orders?${query.join('&')}`,
    params,
  });
}

export function get({ id }) {
  return omsApi({
    url: `/orders/${id}`,
    method: 'get',
  });
}

export function listItem({ id }) {
  return omsApi({
    url: `/orders/${id}/items?infinite=true`,
    method: 'get',
  });
}

export function cancelOrder({ id, ...data }) {
  return omsApi({
    url: `/orders/${id}/cancel`,
    method: 'patch',
    data,
  });
}

export function cancelOrders({ reservationId }) {
  return omsApi({
    url: '/orders/cancel',
    method: 'post',
    data: { reservationId },
  });
}

export function update({ id, ...data }) {
  return omsApi({
    url: `/order-items/${id}`,
    method: 'patch',
    data,
  });
}
