import request from '@/utils/request';

export function list(params) {
  return request({
    url: 'reservations',
    params,
  });
}

export function add(data) {
  return request({
    url: 'reservations',
    method: 'post',
    data,
  });
}

export function get({ id }) {
  return request({
    url: `reservations/${id}`,
  });
}

export function del({ id }) {
  return request({
    url: `reservations/${id}`,
    method: 'delete',
  });
}

export function update({ id, ...data }) {
  return request({
    method: 'put',
    url: `reservations/${id}`,
    data,
  });
}

export function addNeppan(data) {
  return request({
    method: 'post',
    url: 'neppan/reservations',
    data,
  });
}

export function cancelNeppan({ id }) {
  return request({
    method: 'post',
    url: `neppan/reservations/${id}/cancel`,
  });
}

export function getNeppan(params) {
  return request({
    url: 'neppan/reservations',
    params,
  });
}

export function summary(params) {
  return request({
    url: 'reservations/widget/summary',
    params,
  });
}

export function monthly(params) {
  return request({
    url: 'reservations/widget/monthly',
    params,
  });
}

export function countGroupByOta(params) {
  return request({
    url: 'reservations/widget/ota',
    params,
  });
}

export function csv(params) {
  return request({
    url: 'reservations/csv',
    params,
    method: 'post',
    responseType: 'blob',
  });
}

export function addAnshin({ id }) {
  return request({
    url: `reservations/${id}/anshin`,
    method: 'post',
  });
}

export async function calendar(params) {
  return request({
    url: '/calendar/reservation',
    method: 'get',
    params,
  });
}
