import { Service } from '@/utils/request';

const request = Service({
  baseURL: `${process.env.VUE_APP_KAITORI_API_HOST}/api/`,
  timeout: 480000,
});

export function ratePerRoom(params) {
  const { vendor } = params;
  let { dates } = params;
  let { fromDate, toDate } = params.availablePeriod;
  if (dates?.length) {
    dates = dates.sort((a, b) => (a > b ? 1 : -1));
    fromDate = dates.shift();
    toDate = dates.pop();
  }
  delete params.availablePeriod;
  delete params.vendor;
  delete params.dates;
  const query = [];
  if (fromDate != null) query.push(`availablePeriod[fromDate]=${fromDate}`);
  if (toDate != null) query.push(`availablePeriod[toDate]=${toDate}`);

  return request({
    url: `/${vendor}/rate/per-room?${query.join('&')}`,
    params,
  });
}

export function ratePerPerson(params) {
  const { vendor } = params;
  const { fromDate, toDate } = params.availablePeriod;
  delete params.availablePeriod;
  delete params.vendor;
  const query = [];
  if (fromDate != null) query.push(`availablePeriod[fromDate]=${fromDate}`);
  if (toDate != null) query.push(`availablePeriod[toDate]=${toDate}`);

  return request({
    url: `/${vendor}/rate/per-person?${query.join('&')}`,
    params,
  });
}

export function updateRatePerRoom(params) {
  const data = { ...params };
  const { vendor } = data;
  delete data.vendor;

  return request({
    url: `/${vendor}/rate/per-room`,
    method: 'post',
    data,
  });
}

export function updateRatePerPerson(params) {
  const data = { ...params };
  const { vendor } = data;
  delete data.vendor;

  return request({
    url: `/${vendor}/rate/per-person`,
    method: 'post',
    data,
  });
}
