import rmsApi from './api';

export function list(params) {
  return rmsApi({
    url: '/room-bottoms',
    params,
  });
}

export function get({ id }) {
  return rmsApi({
    url: `/room-bottoms/${id}`,
  });
}

export function create(data) {
  return rmsApi({
    url: '/room-bottoms',
    method: 'post',
    data,
  });
}

export function update({ id, ...data }) {
  return rmsApi({
    url: `/room-bottoms/${id}`,
    method: 'put',
    data,
  });
}

export function deleteRecord({ id }) {
  return rmsApi({
    url: `/room-bottoms/${id}`,
    method: 'delete',
  });
}
