import request from '@/utils/request';

export async function list(params) {
  return request({
    url: '/properties',
    method: 'get',
    params,
  });
}

export async function names(params) {
  return request({
    url: '/properties/names',
    method: 'get',
    params,
  });
}

export async function getFee({ id }) {
  return request({
    url: `/properties/${id}/fee`,
    method: 'get',
  });
}

export async function getPriceAutoReflection({ id }) {
  return request({
    url: `/properties/${id}/auto-reflection`,
    method: 'get',
  });
}

export async function update({ id, ...data }) {
  return request({
    url: `/properties/${id}`,
    method: 'put',
    data,
  });
}

export async function useRoomType({ id, ...data }) {
  return request({
    url: `/properties/${id}/use-room-type`,
    method: 'put',
    data,
  });
}

export function uploadLayout({ id, file }) {
  const formData = new FormData();
  formData.append('layout', file.originFileObj || file);

  return request({
    url: `/properties/${id}/layout`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}

export function deleteProperty({ id }) {
  return request({
    url: `/properties/${id}`,
    method: 'delete',
  });
}

export function add(data) {
  return request({
    url: '/properties',
    method: 'post',
    data,
  });
}

export function get({ id }) {
  return request({
    url: `/properties/${id}`,
    method: 'get',
  });
}

export function owners() {
  return request({
    url: '/properties/owners',
    method: 'get',
  });
}

export function propertyOwners(propertyId) {
  return request({
    url: `/properties/${propertyId}/owners`,
    method: 'get',
  });
}

export function addOwner({ propertyId, ...data }) {
  return request({
    url: `/properties/${propertyId}/owners`,
    method: 'post',
    data,
  });
}

export function removeOwner({ propertyId, partnerId }) {
  return request({
    url: `/properties/${propertyId}/owners/${partnerId}`,
    method: 'delete',
  });
}

export function housekeepingSchedule(propertyId, params = {}) {
  return request({
    url: `/properties/${propertyId}/housekeeping-schedule`,
    method: 'get',
    params,
  });
}

export function listAnshinAccommodations() {
  return request({
    url: 'rooms/anshin',
  });
}

export function listAnshinGroups({ anshinAccountId, params }) {
  return request({
    url: `properties/anshinGroups/${anshinAccountId}`,
    params,
  });
}

export function anshinGroupById({ id, anshinAccountId }) {
  return request({
    url: `properties/anshinGroups/${anshinAccountId}/${id}`,
  });
}

export function listAnshinAccounts() {
  return request({
    url: 'properties/anshinAccounts',
  });
}

export function listNeppanAccounts(params) {
  return request({
    url: 'properties/neppanAccounts',
    params,
  });
}
