import rmsApi from './api';

export function getConfiguration() {
  return rmsApi({
    url: '/toplines/configurations',
  });
}

export function saveConfiguration(data) {
  return rmsApi({
    url: '/toplines/configurations',
    method: 'POST',
    data,
  });
}

export function list(params) {
  return rmsApi({
    url: '/toplines',
    method: 'GET',
    params,
  });
}

export function create(data) {
  return rmsApi({
    url: '/toplines',
    method: 'POST',
    data,
  });
}

export function update({ id, ...data }) {
  return rmsApi({
    url: `/toplines/${id}`,
    method: 'PATCH',
    data,
  });
}

export function deletes(id) {
  return rmsApi({
    url: `/toplines/${id}`,
    method: 'DELETE',
  });
}

export function shows(id) {
  return rmsApi({
    url: `/toplines/${id}`,
  });
}

export function exports(params) {
  return rmsApi({
    url: '/toplines/export',
    method: 'POST',
    params,
    responseType: 'blob',
  });
}

export function imports({ file }) {
  const formData = new FormData();
  formData.append('topline', file);

  return rmsApi({
    url: '/toplines/import',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}

export function listTravelWebsites() {
  return rmsApi({
    url: '/toplines/travel-websites',
    method: 'GET',
  });
}
