import request from '@/utils/request';

export async function list(params) {
  return request({
    url: '/lost-and-found',
    method: 'get',
    params,
  });
}

export async function update({ id, ...data }) {
  return request({
    url: `/lost-and-found/${id}`,
    method: 'put',
    data,
  });
}

export function del({ id }) {
  return request({
    url: `/lost-and-found/${id}`,
    method: 'delete',
  });
}

export function add(data) {
  return request({
    url: '/lost-and-found',
    method: 'post',
    data,
  });
}

export function get(id) {
  return request({
    url: `/lost-and-found/${id}`,
    method: 'get',
  });
}

export function addImages({ id, file }) {
  const formData = new FormData();
  file.forEach((f) => {
    if (f.id === undefined) {
      formData.append('images', f.originFileObj || f);
    }
  });
  return request({
    url: `lost-and-found/${id}/images`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}

export function deleteImage({ id, imageId }) {
  return request({
    url: `lost-and-found/${id}/images/${imageId}`,
    method: 'delete',
  });
}
