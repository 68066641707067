import omsApi from './api';

export async function list(params) {
  return omsApi({
    url: '/upsell-items',
    method: 'get',
    params,
  });
}

export async function add(data) {
  return omsApi({
    url: '/upsell-items',
    method: 'post',
    data,
  });
}

export async function addImages({ id, file }) {
  const formData = new FormData();
  if (file) {
    formData.append('images', file.originFileObj || file);
  }

  return omsApi({
    url: `/upsell-items/${id}/images`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}

export function del({ id }) {
  return omsApi({
    url: `/upsell-items/${id}`,
    method: 'delete',
  });
}

export async function update(data) {
  return omsApi({
    url: `/upsell-items/${data.id}`,
    method: 'patch',
    data,
  });
}
