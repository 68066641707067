import request from '@/utils/request';

export function list(params) {
  return request({
    url: '/housekeeping-cycles',
    params,
  });
}

export function add(data) {
  return request({
    url: 'housekeeping-cycles',
    method: 'post',
    data,
  });
}

export function get(id) {
  return request({
    url: `housekeeping-cycles/${id}`,
  });
}

export function update({ id, ...data }) {
  return request({
    url: `housekeeping-cycles/${id}`,
    method: 'put',
    data,
  });
}

export function active(id) {
  return request({
    url: `housekeeping-cycles/${id}/active`,
    method: 'put',
  });
}

export function inactive(id) {
  return request({
    url: `housekeeping-cycles/${id}/inactive`,
    method: 'put',
  });
}

export function del(id) {
  return request({
    url: `housekeeping-cycles/${id}`,
    method: 'delete',
  });
}
