import request from '@/utils/request';

export async function list(params) {
  return request({
    url: '/tasks',
    method: 'get',
    params,
  });
}

export async function update({ id, ...data }) {
  return request({
    url: `/housekeeper-tasks/${id}`,
    method: 'put',
    data,
  });
}

export function del({ id }) {
  return request({
    url: `/housekeeper-tasks/${id}`,
    method: 'delete',
  });
}

export function add(data) {
  return request({
    url: '/housekeeper-tasks',
    method: 'post',
    data,
  });
}

export function get({ id }) {
  return request({
    url: `/tasks/${id}`,
    method: 'get',
  });
}

export function getChecklist({ id }) {
  return request({
    url: `/housekeeper-tasks/${id}/checklist`,
    method: 'get',
  });
}

export function start(assignmentId) {
  return request({
    url: `/roomAssignments/${assignmentId}/start`,
    method: 'put',
  });
}

export function finish(assignmentId) {
  return request({
    url: `/roomAssignments/${assignmentId}/finish`,
    method: 'put',
  });
}

export function checkChecklist({ assignmentId, id }) {
  return request({
    url: `roomAssignments/${assignmentId}/tasks/${id}/complete`,
    method: 'put',
  });
}

export function uncheckChecklist({ assignmentId, id }) {
  return request({
    url: `roomAssignments/${assignmentId}/tasks/${id}/incomplete`,
    method: 'put',
  });
}

export function remainingTask({ assignmentId }) {
  return request({
    url: `roomAssignments/${assignmentId}/tasks/remaining`,
  });
}

export function taskByAssignment({ assignmentId }) {
  return request({
    url: `roomAssignments/${assignmentId}/tasks`,
  });
}
