import request, { CancelToken } from '@/utils/request';

let listSource = CancelToken.source();

export async function list(params) {
  listSource.cancel('ROOM_TYPES_LIST_CANCEL');

  listSource = CancelToken.source();

  const response = await request({
    url: '/roomTypes',
    params,
    cancelToken: listSource.token,
  });

  return response;
}

export function add(data) {
  return request({
    url: '/roomTypes',
    method: 'post',
    data,
  });
}

export function update({ id, ...data }) {
  return request({
    url: `/roomTypes/${id}`,
    method: 'put',
    data,
  });
}

export function del(id) {
  return request({
    url: `roomTypes/${id}`,
    method: 'delete',
  });
}
