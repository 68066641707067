import request from '@/utils/request';

export async function list(params) {
  return request({
    url: '/facilities',
    method: 'get',
    params,
  });
}

export async function categories(params) {
  return request({
    url: '/facilities/category',
    method: 'get',
    params,
  });
}

export async function subCategories(params) {
  return request({
    url: '/facilities/subCategory',
    method: 'get',
    params,
  });
}

export async function update({ id, ...data }) {
  return request({
    url: `/facilities/${id}`,
    method: 'put',
    data,
  });
}

export function del({ id }) {
  return request({
    url: `/facilities/${id}`,
    method: 'delete',
  });
}

export function add(data) {
  return request({
    url: '/facilities',
    method: 'post',
    data,
  });
}

export function get({ id }) {
  // return {}

  // eslint-disable-next-line no-unreachable
  return request({
    url: `/facilities/${id}`,
    method: 'get',
  });
}
