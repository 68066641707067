import rmsApi from './api';

export function list({ settingId, ...params }) {
  return rmsApi({
    url: `/settings/${settingId}/fractions`,
    params,
  });
}

export function add({ settingId, ...data }) {
  return rmsApi({
    method: 'post',
    url: `/settings/${settingId}/fractions`,
    data,
  });
}

export function edit({ id, settingId, ...data }) {
  return rmsApi({
    method: 'put',
    url: `/settings/${settingId}/fractions/${id}`,
    data,
  });
}

export function deletes({ id, settingId }) {
  return rmsApi({
    method: 'delete',
    url: `/settings/${settingId}/fractions/${id}`,
  });
}
