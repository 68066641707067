import request from '@/utils/request';

export function housekeepingCompanies(params) {
  return request({
    url: '/reports/housekeeping-companies',
    method: 'get',
    params,
  });
}

export function housekeepingCompaniesExport(params) {
  return request({
    url: '/reports/housekeeping-companies/export',
    method: 'post',
    params,
    responseType: 'blob',
  });
}

export function lostFounds(params) {
  return request({
    url: '/reports/lost-founds',
    method: 'get',
    params,
  });
}

export function lostFoundsExport(params) {
  return request({
    url: 'reports/lost-founds/export',
    method: 'post',
    params,
    responseType: 'blob',
  });
}

export function outOfOrder(params) {
  return request({
    url: '/reports/out-of-order',
    method: 'get',
    params,
  });
}

export function outOfOrderExport(params) {
  return request({
    url: '/reports/out-of-order/export',
    method: 'post',
    params,
    responseType: 'blob',
  });
}

export function stocks(params) {
  return request({
    url: '/reports/stocks',
    method: 'get',
    params,
  });
}

export function stocksExport(params) {
  return request({
    url: '/reports/stocks/export',
    method: 'post',
    params,
    responseType: 'blob',
  });
}

export function expenses(params) {
  return request({
    url: '/reports/expenses',
    method: 'get',
    params,
  });
}

export function expensesExport(params) {
  return request({
    url: '/reports/expenses/export',
    method: 'post',
    params,
    responseType: 'blob',
  });
}

export function expensesPaymentExport(params) {
  return request({
    url: '/reports/expenses/payment-export',
    method: 'post',
    params,
    responseType: 'blob',
  });
}

export async function expensesApprove({ ids }) {
  return request({
    url: '/reports/expenses/approve',
    method: 'post',
    data: { ids },
  });
}

export async function expensesReject(payload) {
  return request({
    url: '/reports/expenses/reject',
    method: 'post',
    data: payload,
  });
}

export async function reservationCounterList(params) {
  return request({
    url: 'reports/reservation-counter',
    method: 'get',
    params,
  });
}

export async function reservationCounterExport(params) {
  return request({
    url: 'reports/reservation-counter/export',
    method: 'post',
    params,
    responseType: 'blob',
  });
}

export async function reservationCounterHouseKeepingTotal(params) {
  return request({
    url: 'reports/reservation-counter/housekeeping-total',
    method: 'get',
    params,
  });
}

export async function downloadReservationsReport(params) {
  return request({
    url: 'reports/reservations/download',
    method: 'post',
    params,
    responseType: 'blob',
  });
}
